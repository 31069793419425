body {
  font-family: 'Lato', sans-serif;
}

* {
  box-sizing: border-box;
}

a:link {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}

a:visited {
    text-decoration: inherit;
    color: inherit;
    cursor: pointer;
}

h1 {
  font-size: 64px;
}

h2 {
  font-size: 48px;
}

h3 {
  font-size: 32px;
}

h4 {
  font-size: 24px;
}

h5 {
  font-size: 20px;
}

h6 {
  font-size: 18px;
}

strong {
  font-weight: bolder;
}

em {
  font-style: italic;
}



.default-theme-date-picker .react-datepicker__day--keyboard-selected {
  background-color: #3de2ae;
}

.default-theme-date-picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #3de2ae;
}

.default-theme-date-picker .react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #3de2ae;
}

.default-theme-date-picker .react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  background-color: #5fd8bf;
}

.default-theme-date-picker .react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
  background-color: #5fd8bf;
}

.default-theme-date-picker .react-datepicker__day--keyboard-selected {
  background-color: #3de2ae;
}

.default-theme-date-picker .react-datepicker__day--keyboard-selected:hover {
  background-color: #5fd8bf;
}


.default-theme-date-picker .react-datepicker__close-icon::after {
  background-color: #3de2ae;
}

::-webkit-scrollbar {
  width: 6px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
   background: #555;
}

.recorder-overlay {
  position: fixed;
  z-index: 9999;
  top: 0px;
  background-color: rgba(255, 255, 255, 0.75);
  left: 0px;
  width: 100%;
  bottom: 0px;
  right: 0px;
}

#scrollBox .infinite-scroll-component {
    overflow: hidden !important;
}
